<template>
    <loader v-bind="{ loading }">
        <form>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.name" v-model="contact.name">
                        Name
                    </text-input>
                </column>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.surname"
                        v-model="contact.surname">
                        Last Name
                    </text-input>
                </column>
            </columns>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.email" v-model="contact.email">
                        Email Address
                    </text-input>
                </column>
                <column>
                    <text-input classes="is-medium is-rounded" v-model="contact.mobile" :error="$root.errors.mobile">
                        Mobile <small>(Optional)</small>
                    </text-input>
                </column>
                <column>
                    <text-input classes="is-medium is-rounded" v-model="contact.position" :error="$root.errors.position">
                        Position <small>(Optional)</small>
                    </text-input>
                </column>
            </columns>

            <columns>
                <column>
                    <data-selector searchable multiple required :items="locations" v-model="contact.locations"
                        value-key="uuid" label-key="name" classes="is-medium is-rounded" tag-class="is-medium is-rounded"
                        delete-button-class="is-medium is-rounded is-danger">
                        Locations
                    </data-selector>
                </column>
            </columns>

            <columns>
                <column>
                    <h4 class="title is-4">Notification Preferences</h4>
                </column>
            </columns>

            <columns>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.work_order_opened">
                        New Work Order Opened
                    </switch-input>
                </column>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.work_order_completion">
                        Work Order Completion Report
                    </switch-input>
                </column>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.checklist_completion">
                        Checklist Completion Report
                    </switch-input>
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button :working="saving" @submit="updateContact" class="is-rounded">
                        Save
                    </submit-button>
                </column>
                <column class="is-narrow">
                    <action-button :working="deleting" left-icon="trash" @click="deleteContact" class="is-rounded is-small is-danger">
                        Delete
                    </action-button>
                </column>
            </columns>
        </form>
    </loader>
</template>
<script>
import { customer as backend } from '@/api'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true,
        saving: false,
        deleting: false,
        locations: [],
        contact: {
            name: '',
            surname: '',
            email: '',
            mobile: '',
            position: '',
            locations: [],
            notification_preferences: {
                checklist_completion: false,
                work_order_completion: false,
                work_order_opened: false
            }
        }
    }),

    beforeCreate() {
        backend.loadCustomerLocationsList(this.$route.params.customer, ({ data }) => {
            this.locations = data
            this.loading = false
        }, () => this.loading = false)

        backend.loadContact({
            customer_uuid: this.$route.params.customer,
            contact_uuid: this.$route.params.contact,
        }, ({data}) => {
            this.contact = data
        }, () => {})
    },

    methods: {
        updateContact() {
            this.saving = true
            const contact = { ...this.contact }
            contact.locations = contact.locations.map(location => location.uuid)
            backend.updateContact({
                customer_uuid: this.customer.uuid,
                ...contact
            }, () => {
                this.saving = false
                this.$toast.success('Contact updated')
            }, () => this.saving = false)
        },
        async deleteContact() {
            if(await this.$confirm({
                title: 'Delete Contact',
                message: 'Are you sure you want to delete this contact?'
            })) {
                this.deleting = true
                backend.deleteContact({
                    customer_uuid: this.customer.uuid,
                    uuid: this.contact.uuid
                }, () => {
                    this.$toast.success('Deleted contact')
                    this.$router.back()
                    this.deleting = false
                }, () => this.deleting = false)
            }
        }
    },

    computed: {
        ...mapGetters('customer', [
            'customer'
        ])
    },

}
</script>